/* start admin dashboard page structure css */

.dashboard-left-main {
      flex: 0 0 290px;
      max-width: 290px;
      width: 100%;
      transition: 0.5s all;
}

.dashboard-right-main {
      flex: 1;
}


.dashboard-containt-main {
      background-color: #FFFFFF;
      min-height: 100vh;
      padding: 88px 24px 24px 24px;
}

.dashboard-main {
      display: flex;
      width: 100%;
}

/* end admin dashboard page structure css */






















