/* start admin sidebar css */
.admin-sidebar-logo-main .sidebar-close-btn {
      display: none;
}

.admin-sidebar-icons {
      margin-right: 8px;
      height: 20px;
      width: 20px;
      object-fit: contain;
}

.admin-sidebar-main {
      background-color: #114A65;
      height: 100vh;
      left: -500px;
      overflow: auto;
      /* padding: 0px 16px; */
      position: fixed;
      top: 0;
      width: 290px;
      z-index: 9999;
      border-right: 1px solid #E5E7EB;
      transition: 0.5s all;

}

.admin-sidebar-main.active {
      left: 0px;
}

.admin-sidebar-deactive {
      flex: 0 0 0px;
      max-width: 0px;
      width: 100%;
      transition: 0.5s all;
}

.admin-sidebar-logo {
      height: 55px;
      width: 100%;
      object-fit: contain;
      margin: auto;
      display: flex;
      order: 2;
}

.close-icon {
      display: none;
}

.admin-sidebar-logo-main {
      padding: 5px 0px;
}

.admin-sidebar-list-main {
      padding: 16px 16px;
}

.admin-header-drop-main .drop-header-btn {
      padding: 0px;
}

.admin-sidebar-list-main .admin-sidebar-list {
      padding: 0px;
}

.admin-sidebar-list-main .admin-sidebar-listitem {
      padding: 0px;
}

.admin-sidebar-list-main .admin-sidebar-link {
      font-size: 14px;
      line-height: 21px;
      font-family: 'Poppins-Regular';
      color: #FFFFFF;
      /* display: block; */
      padding: 10px 12px 10px;
      margin-bottom: 10px;
      width: 100%;
      text-decoration: none;
}

.admin-sidebar-list-main .admin-sidebar-link.active {
      color: #FFFFFF;
      background-color: #1B698E;
      border-radius: 4px;
}

.admin-sidebar-main::-webkit-scrollbar {
      width: 2px;
}


.admin-sidebar-listitem .expandless-icon,
.admin-sidebar-listitem .expandmore-icon {
      position: absolute;
      right: 10px;
      top: 10px;
}

.admin-sidebar-submenulist .admin-sidebar-link {
      margin-left: 10px;
}

.admin-sidebar-submenulist .admin-sidebar-listitem:first-child .admin-sidebar-link {
      margin-top: 20px;
}

.admin-sidebar-main::-webkit-scrollbar {
      width: 2px;
}

.admin-sidebar-main::-webkit-scrollbar-track {
      background: #f1f1f1;
}

.admin-sidebar-main::-webkit-scrollbar-thumb {
      background: #888;
}

.admin-sidebar-main::-webkit-scrollbar-thumb:hover {
      background: #555;
}

/* end admin sidebar css */






/* start admin header css */


.admin-header-main {
      background-color: #FFFFFF;
      position: fixed;
      top: 0px;
      left: 290px;
      right: 0px;
      z-index: 9;
      padding: 15px 20px 13px 20px;
      border-bottom: 1px solid #E5E7EB;
      transition: 0.5s all;
}

.admin-header-deactive {
      left: 0px;
}

.admin-header-main.active .admin-sidebar-logo {
      opacity: 0;
      display: none;
}

.admin-header-logo-main .admin-bergur-button {
      padding: 0px;
      min-width: 30px;
}

.admin-header-main.admin-header-deactive .admin-sidebar-logo {
      opacity: 1;
      transition: 0.5s all;
      margin-left: 10px;
      height: 35px;
}

.admin-header-logo-main {
      display: flex;
}

.admin-header-left {
      display: flex;
      justify-content: space-between;
      align-items: center;
}

.admin-header-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
}

.admin-header-right .admin-header-icon-box {
      padding: 0px;
      min-width: 30px;
      margin-right: 23px;
}


.admin-header-icon {
      height: 20px;
      width: 20px;
      object-fit: contain;
}

.admin-header-profile-icon {
      height: 36px;
      width: 36px;
      object-fit: cover;
}

/* end admin header css */





/* start header profile dropdown  */

.flex-drop-main .admin-header-drop {
      font-size: 12px;
      font-family: 'Poppins-Regular';
      line-height: 18px;
      color: #6B7280;
      text-align: start;
      margin-left: 12px;
      text-transform: capitalize;
}

.flex-drop-main .header-drop-title {
      font-family: 'Poppins-Bold';
}

.flex-drop-main {
      display: flex;
      align-items: center;
}

.drop-header {
      height: 15px;
      width: 15px;
      margin-right: 6px;
}

.drop-header-menu .MuiPaper-rounded {
      box-shadow: 10px 10px 20px #0000001A;
      border: 1px solid #E5E7EB;
      margin-top: 13px;
      border-radius: 1px;
}

.drop-header {
      height: 15px;
      width: 15px;
      margin-right: 6px;
}

.drop-header-menu .drop-header-menuitem {
      font-size: 12px;
      line-height: 14px;
      color: #6B7280;
      font-family: 'Poppins-Regular';
      border-bottom: 1px solid #E5E7EB;
      padding: 11px 16px;
}

.drop-header-menu ul {
      padding: 0px;
}

/* end header profile dropdown  */


/* start auth footer social meadia */

.social-main .social-ul {
      padding: 0px;
      margin: 0px;
      list-style-type: none;
      display: flex;
      margin: 20px 0px;
      justify-content: center;
}

.social-main .social-ul .social-li {
      padding: 0px;
      margin: 0px;
      width: max-content;
}

.social-box {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      border: 1px solid #D1D5DB;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
}

.social-icons {
      height: 15px;
      width: 15px;
      object-fit: contain;
}

.social-main .social-ul .social-li:last-child .social-box {
      margin-right: 0px;
}

/* end auth footer social meadia */