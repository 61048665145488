

.admin-card-box {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #D1D5DB;
      border-radius: 6px;
      padding: 10px;
      position: relative;
}

.admin-card-img-main {
      display: flex;
}

.card-img {
      width: 90px;
      height: 90px;
      border-radius: 10px;
      object-fit: cover;
}

.card-data-flex {
      display: flex;
      margin: 10px 0px;
}

.card-data-flex .card-user-lable {
      font-size: 12px;
      line-height: 18px;
      color: #111827;
      font-family: 'Poppins-Medium';
      max-width: 200px;
      margin-right: 10px;
}

.card-data-flex .card-user-data {
      font-size: 12px;
      line-height: 18px;
      color: #6B7280;
      font-family: 'Poppins-Regular';
}

.admin-user-list-row {
      margin: 30px 0px;
}

.admin-card-box .card-user-title {
      color: #114A65;
      font-size: 17px;
      line-height: 22px;
      font-family: 'Poppins-Medium';
      margin: 0px 0px 5px;
}

.user-card-flex {
      display: flex;
      align-items: center;
}

.card-containt-main {
      margin-left: 20px;
}

.user-list-row {
      margin-bottom: 50px;
}

.card-data-flex:last-child {
      margin-bottom: 0px;
}

.card-delete-icon {
      position: absolute;
      right: 6px;
      top: 6px;
      height: 15px;
      width: 15px;
      object-fit: contain;
}

.card-pagination-main {
      margin-right: 0px;
}