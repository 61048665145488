

/* start user list page */

.userdata-btn-flex {
      display: flex;
}

.userdata-btn-flex button {
      min-width: 30px;
}

.userlist-data {
      display: flex;
      align-items: center;
      width: 100%;
}

.userlist-img {
      margin-right: 15px;
      height: 25px;
      width: 25px;
      object-fit: cover;

}

.userlist-table-main .table {
      margin: 0px;
}

.userlist-table-main .table .table-th {
      padding: 10px 10px 10px 20px;
}

.userlist-table-main .table .table-td {
      padding: 10px 10px 10px 20px;
}

.userlist-table-main .table .table-th:nth-child(1),
.userlist-table-main .table .table-td:nth-child(1) {
      width: 10%;
}

.userlist-table-main .table .table-th:nth-child(2),
.userlist-table-main .table .table-td:nth-child(2) {
      width: 20%;
}

.userlist-table-main .table .table-th:nth-child(3),
.userlist-table-main .table .table-td:nth-child(3) {
      width: 15%;
}

.userlist-table-main .table .table-th:nth-child(4),
.userlist-table-main .table .table-td:nth-child(4) {
      width: 15%;
}

.userlist-table-main .table .table-th:nth-child(5),
.userlist-table-main .table .table-td:nth-child(5) {
      width: 10%;
}

.userlist-table-main .table .table-th:nth-child(6),
.userlist-table-main .table .table-td:nth-child(6) {
      width: 10%;
}

.userlist-table-main .table .table-th:nth-child(7),
.userlist-table-main .table .table-td:nth-child(7) {
      width: 10%;
}
.userlist-inner-btn-flex{
      display: flex;
  }

.down-icon,
.plus-icon {
      width: 15px;
      height: 15px;
      object-fit: contain;
      margin-right: 9px;
}

.export-btn-main .export-btn,
.export-btn-main .export-btn:hover {
      margin-right: 10px;
}


.user-search-box {
      position: relative;
      margin-right: 10px;
}
.user-search-box .form-control input::placeholder {
      color: #6B7280;
      font-size: 12px;
      line-height: 20px;
      font-family: 'Poppins-Regular';
      opacity: 1;
}

.search-grey-img {
      position: absolute;
      left: 13px;
      top: 14px;
      height: 14px;
      width: 14px;
      margin-right: 8px;
}
.user-search-box .form-control input{
      padding: 10px 35px;
}
.user-list-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
}

.userlist-btn-flex {
      display: flex;
      flex-wrap: wrap;
}

.user-list-flex .user-list-page-title {
      margin-bottom: 0px;
}

/* end user list page */


/* start add user modal css */

.span-text {
      color: #D1D5DB;
      margin-left: 3px;
}

.modal .modal-title {
      color: #111827;
      font-size: 20px;
      line-height: 30px;
      font-family: 'Poppins-Medium';
}

.modal-user-btn-flex {
      display: flex;
      justify-content: flex-end;
      padding-top: 3px;
}

.modal .add-user-modal-inner-main {
      padding: 24px 24px 30px 24px;
      max-width: 500px;
      width: 100%;
      border: 1px solid #707070;
      border-radius: 10px;
}

.modal-input-box {
      margin-bottom: 24px;
}

.modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
}

/* end add user modal css */



/* start delete user modal css */

.user-circle-img {
      width: 52px;
      height: 52px;
      object-fit: contain;
      margin: auto;
      display: flex;
}

.modal-delete .delete-modal-inner-main {
      padding: 40px 24px 40px 24px;
      max-width: 500px;
      width: 100%;
      border: 1px solid #707070;
      border-radius: 10px;
}

.delete-modal-inner-main .delete-modal-title {
      color: #111827;
      font-size: 28px;
      line-height: 42px;
      font-family: 'Poppins-Medium';
      text-align: center;
      margin: 20px 0px 16px;
}

.delete-modal-inner-main .delete-modal-para {
      color: #6B7280;
      margin-bottom: 26px;
      text-align: center;
      max-width: 304px;
      width: 100%;
      margin: auto;
}

.delete-modal-btn-flex {
      display: flex;
      justify-content: center;
      margin-top: 26px;
}

.delete-modal-btn-flex .modal-btn {
      border-radius: 4px;
      border: 1px solid #D1D5DB;
      max-width: 100px;
      width: 100%;
      padding: 8px;
      text-transform: capitalize;
}

.delete-modal-btn-flex .modal-cancel-btn {
      color: #6B7280;
      margin-right: 20px;
}

.delete-modal-btn-flex .modal-delete-btn,
.delete-modal-btn-flex .modal-delete-btn:hover {
      color: #FFFFFF;
      background-color: #E4004D;
}

.MuiBackdrop-root-MuiModal-backdrop {
      background-color: rgb(17 24 39 / 60%);
}

/* end delete user modal css */





/* start filter css */

.filter-main {
      position: relative;
}

.filter-main .MuiDrawer-paper {
      width: 100%;
      min-width: 400px;
      max-width: 400px;
}

.filter-header {
      background-color: #07415D;
      display: flex;
      justify-content: space-between;
      padding: 16px 25px;
      align-items: center;
}

img.filter-close-icon {
      height: 21px;
      width: 21px;
      object-fit: contain;
}

.filter-header .filter-title {
      font-size: 20px;
      line-height: 30px;
      color: #FFFFFF;
      font-family: 'Poppins-Regular';
}

.filter-inner-main {
      padding: 20px 25px;
      background-color: #fff;
}

.filter-btn-main {
      display: flex;
      justify-content: flex-end;
}

.filter-btn-main .filter-cancel-btn {
      min-width: 90px;
      margin-right: 20px;
}

.filter-btn-main .filter-btn,
.filter-btn-main .filter-btn:hover {
      max-width: 90px;
}

.filter-footer {
      border-top: 1px solid #D1D5DB;
      padding: 15px;
      position: absolute;
      bottom: 0px;
      width: 100%;
      left: 0px;
      right: 0px;
}

.filter-checkbox-main .checkbox-lable {
      margin-right: 20px;
}
/* end filter css */


