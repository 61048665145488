* {
      box-sizing: border-box;
      padding: 0px;
      margin: 0px;
}

@font-face {
      font-family: 'Poppins-Regular';
      src: url(../fonts/Poppins/Poppins-Regular.ttf);
}

@font-face {
      font-family: 'Poppins-Bold';
      src: url(../fonts/Poppins/poppins-bold.ttf);
}

@font-face {
      font-family: 'Poppins-Medium';
      src: url(../fonts/Poppins/Poppins-Medium.ttf);
}

@font-face {
      font-family: 'Poppins-SemiBold';
      src: url(../fonts/Poppins/Poppins-SemiBold.ttf);
}

.grid-main .grid-item {
      background-color: transparent;
      box-shadow: none;
      border-radius: 0px;
      padding: 0px;
}

.text-decoration-none {
      text-decoration: none !important;
}

.flex-all {
      display: flex;
      justify-content: space-between;
}


/* start input field design css */

.form-group .form-control input {
      border: 1px solid #D1D5DB;
      border-radius: 4px;
      width: 100%;
      padding: 10px 15px;
      background-color: transparent;
      color: #6B7280;
      font-size: 12px;
      line-height: 20px;
      font-family: 'Poppins-Regular';
}

.form-group .form-control input::placeholder {
      color: #6B7280;
      font-size: 12px;
      line-height: 20px;
      font-family: 'Poppins-Regular';
      opacity: 1;
}

.form-group .form-control .Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
      border-width: 0px;
}

.form-control fieldset {
      border: 1px solid #D1D5DB;
      border-radius: 2px;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: #D1D5DB !important;
}

.form-control-textarea:focus-visible {
      outline: none !important;
}

/* end input field design css */



.page-border {
      border: 1px solid #D1D5DB;
      border-radius: 6px;
      padding: 20px;
}

.btn-main-primary .btn-primary,
.btn-main-primary .btn-primary:hover {
      background-color: #114A65;
      border-radius: 4px;
      width: 100%;
      max-width: 380px;
      color: #FFFFFF;
      font-size: 14px;
      line-height: 20px;
      font-family: 'Poppins-Regular';
      padding: 10px 15px;
      text-transform: capitalize;
}

.border-btn-main .border-btn {
      border: 1px solid #D1D5DB;
      border-radius: 4px;
      background-color: #FFFFFF;
      padding: 10px 15px;
      font-size: 12px;
      line-height: 18px;
      color: #6B7280;
      font-family: 'Poppins-Regular';
      text-transform: capitalize;
}

.common-para {
      font-size: 14px !important;
      line-height: 21px !important;
      font-family: 'Poppins-Regular';
}




/* start switch css */

.switch-main {
      margin-left: 20px;
}

.switch-lable .MuiTypography-root {
      font-size: 14px;
      line-height: 21px;
      color: #6B7280;
      font-family: 'Poppins-Regular';
}

/* end switch css */




/* start pagination css */

.pagination-main {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 27px;
}

.pagination-main .MuiPagination-ul {
      margin-right: 20px;
}

.pagination-main .MuiPaginationItem-root {
      height: 20px;
      min-width: 20px;
      border-radius: 4px;
      padding: 0px;
      font-size: 12px;
      line-height: 18px;
      color: #6B7280;
      font-family: 'Poppins-Regular';
      margin: 0 4px;
}

.pagination-main .MuiPaginationItem-root.Mui-selected:hover {
      background-color: #114A65;
      color: #FFFFFF;
}

.pagination-main .MuiPaginationItem-root.Mui-selected {
      background-color: #114A65;
      color: #FFFFFF;
}

/* end pagination css */



/* start progrss bar css */

.progress-bar-main {
      max-width: 470px;
      width: 100%;
      position: relative;
}

.progress-bar-main .progress-bar .MuiLinearProgress-bar {
      border-radius: 8px;
      background-color: #114A65;
}

.progress-bar-content {
      margin-top: 30px;
}

.progress-bar-content .admin-page-title {
      font-size: 20px;
      line-height: 30px;
      color: #111827;
      font-family: 'Poppins-Medium';
}

.progress-bar-text {
      position: absolute;
      right: -35px;
      top: -5px;
      font-size: 14px;
      line-height: 21px;
      color: #6B7280;
      font-family: 'Poppins-Medium';
}

/* end progrss bar css */


/* start error text css */

.error-text {
      color: red;
      font-size: 12px;
      line-height: 15px;
      font-family: 'Poppins-Regular';
}

/* end error text css */




/* / start dropdown css / */

.dropdown-box .form-control {
      width: 100%;
      position: relative;
}

.dropdown-select .MuiSelect-select {
      color: #6B7280;
      font-size: 12px;
      line-height: 20px;
      font-family: 'Poppins-Regular';
      display: flex;
      padding: 10px 15px;
      border-radius: 4px;
}

.grey-down {
      position: absolute;
      top: 17.92px;
      right: 21.92px;
      height: 10px;
      width: 10px;
      object-fit: contain;
}

.dropdown-box .MuiMenu-list .drop-menuitem {
      color: #6B7280;
      font-size: 12px;
      line-height: 20px;
      font-family: 'Poppins-Regular';
}


/* / end dropdown css / */