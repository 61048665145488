
/* start add user page */

.add-user-containt .add-basic-text {
      color: #111827;
      font-family: 'Poppins-SemiBold';
      margin: 0px 0px 4px;
}

.add-user-containt .add-section-text {
      color: #6B7280;
      margin-bottom: 20px;
}

.add-user-input {
      margin-bottom: 24px;
}

.add-user-input .form-control-textarea {
      width: 100%;
      border: 1px solid #D1D5DB;
      border-radius: 4px;
      padding: 10px;
      min-height: 100px;
      resize: none;
}

.radio-main .radiogroup .MuiButtonBase-root.Mui-checked {
      color: #114A65;
}

.radio-main .MuiFormControlLabel-label {
      color: #6B7280;
      font-size: 14px;
      line-height: 21px;
      font-family: 'Poppins-Regular';
}

.user-btn-flex {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 60px;
}

/* end add user page */




/* start admin add user page */


.discard-btn-main .discard-user-btn,
.discard-btn-main .discard-user-btn:hover {
      margin-right: 10px;
}

.user-save-icon {
      margin-right: 8px;
      object-fit: contain;
}

.save-btn-main .save-user-btn,
.save-btn-main .save-user-btn:hover {
      background-color: #114A65;
      color: #FFFFFF;
}

.admin-tabs-main .admin-tab {
      font-size: 14px;
      line-height: 21px;
      font-family: 'Poppins-Medium';
      color: #111827;
      text-transform: capitalize;
      padding: 10px 15px;
}

.admin-tabs-main .admin-tab.Mui-selected {
      color: #114A65;
}

.admin-tabs-main .MuiTabs-indicator {
      background-color: #114A65;
      height: 2px;
}

.admin-tabpanel .edit-highlight-text {
      color: #111827;
      font-family: 'Poppins-Medium';
      margin-bottom: 4px;
}

.admin-tabpanel .edit-para-text {
      margin-bottom: 24px;
      color: #6B7280;
}

.admin-tabpanel>.MuiBox-root {
      padding: 24px 0px;
}

/* end admin add user page */