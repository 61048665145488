/* start admin dashboard page */

.dashboard-content .admin-page-title {
      color: #111827;
      font-size: 26px;
      line-height: 39px;
      font-family: 'Poppins-SemiBold';
      margin-bottom: 20px;
}

.admin-dashboard-inner-box {
      border: 1px solid #D1D5DB;
      max-width: 100%;
      width: 100%;
      height: 90px;
      border-radius: 6px;
      padding: 15px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
}



.admin-dashboard-inner-box .admin-dash-text {
      color: #6B7280;
      font-size: 12px;
      line-height: 18px;
      font-family: 'Poppins-Regular';
}

.admin-dashboard-inner-box .admin-dash-price {
      color: #111827;
      font-size: 30px;
      line-height: 46px;
      font-family: 'Poppins-Medium';
}

.dash-icons {
      height: 50px;
      width: 50px;
      object-fit: contain;
}


.page-table-main .table-container {
      padding-bottom: 7px;
      background-color: transparent;
      box-shadow: none;
      overflow: auto;
      max-width: calc(100vw - 0px);
}

.page-table-main .table {
      margin: 0px 19px;
      min-width: 1100px;
}

.page-table-main .table-th {
      font-size: 12px;
      line-height: 18px;
      color: #111827;
      font-family: 'Poppins-Medium';
      padding: 10px 10px 10px 0px;
      border-bottom: 1px solid #D1D5DB;
}

.page-table-main .table .table-td:first-child {
      color: #111827;
}

.page-table-main .table-td {
      font-size: 12px;
      line-height: 18px;
      color: #6B7280;
      font-family: 'Poppins-Regular';
      padding: 10px 10px 10px 0px;
      border-bottom: 1px solid #D1D5DB;
}

.page-table-main .table .table-th:nth-child(1),
.page-table-main .table .table-td:nth-child(1) {
      width: 5%;
}

.page-table-main .table .table-th:nth-child(2),
.page-table-main .table .table-td:nth-child(2) {
      width: 5%;
}

.page-table-main .table .table-th:nth-child(3),
.page-table-main .table .table-td:nth-child(3) {
      width: 7%;
}

.page-table-main .table .table-th:nth-child(4),
.page-table-main .table .table-td:nth-child(4) {
      width: 7%;
}

.page-table-main .table .table-th:nth-child(5),
.page-table-main .table .table-td:nth-child(5) {
      width: 7%;
}

.admin-sub-title-main .admin-sub-title {
      color: #111827;
      font-size: 20px;
      line-height: 30px;
      font-family: 'Poppins-Medium';
}

.view-btn-main .view-order-btn {
      font-size: 12px;
      line-height: 18px;
}

.view-btn-main .view-order-btn:hover {
      font-size: 12px;
      line-height: 18px;
      background-color: #114A65;
      color: #FFFFFF;
}



.admin-dashboard-table-row {
      border-bottom: 1px solid #D1D5DB;
      padding: 20px 19px;
}

.admin-dash-box {
      border-radius: 8px;
      border: 1px solid #D1D5DB;
      padding: 0px 0px 20px 0px;
}

.admin-dashboad-row {
      margin-bottom: 24px;
}

.display-row>* {
      padding: 0px 8px;
}

.display-row {
      margin-left: -8px;
      margin-right: -8px;
}




.table-lable-main {
      font-family: 'Poppins-Regular';
      font-size: 12px;
      line-height: 18px;
}

.paid-lable-dots {
      background-color: #04AB47;
}

.paid-lable {
      color: #04AB47;
}

.table-lable-dots {
      height: 7px;
      width: 7px;
      border-radius: 50%;
      margin-right: 4px;
}

.table-lable-flex {
      display: flex;
      align-items: center;
}

.failed-lable-dots {
      background-color: #E4004D;
}

.failed-lable {
      color: #E4004D;
}

.pending-lable-dots {
      background-color: #f39c12;
}

.pending-lable {
      color: #f39c12;
}

/* width */
.page-table-main .table-container::-webkit-scrollbar {
      width: 2px;
      height: 5px;
}

/* Track */
.page-table-main .table-container::-webkit-scrollbar-track {
      background: #D1D5DB;
      border-radius: 5px;
}

/* Handle */
.page-table-main .table-container::-webkit-scrollbar-thumb {
      background: #8888885c;
}

/* Handle on hover */
.page-table-main .table-container::-webkit-scrollbar-thumb:hover {
      background: #555;
}
/* end admin dashboard page */